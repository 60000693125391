import "./App.css";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Summary from "./components/Summary.react";
import EventEditor from "./components/EventEditor.react";
import React, { useState } from "react";

function App() {
  const [tab, setTab] = useState("today");
  return (
    <div className="App">
      <header className="App-header">
        <Typography variant="h4" marginTop={8} marginBottom={4}>
          Baby 👶 Events
        </Typography>
        <Tabs
          value={tab}
          sx={{ align: "center", marginLeft: 4 }}
          onChange={(_, selectedTab) => setTab(selectedTab)}
        >
          <Tab label="Yesterday" value="yesterday" />
          <Tab label="Today" value="today" />
          <Tab label="➕ Event" value="addEvent" />
        </Tabs>
        <Box sx={{ p: 3 }}>
          {tab === "yesterday" ? (
            <Summary isActive={tab === "yesterday"} date={"yesterday"} />
          ) : tab === "today" ? (
            <Summary isActive={tab === "today"} date={"today"} />
          ) : (
            <EventEditor isActive={tab === "addEvent"} />
          )}
        </Box>
      </header>
    </div>
  );
}

export default App;
