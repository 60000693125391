import DeleteIcon from "@mui/icons-material/Delete";
import {
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

function EventRow({ event, onDeleteEvent }) {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {new Date(event.timestamp * 1000).toLocaleTimeString("en-US", {
          hourCycle: "h23",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </TableCell>
      <TableCell
        align="right"
        sx={{ fontSize: event.type === "pooping" ? "x-large" : "small" }}
      >
        {event.type === "feeding" ? (
          event.misc.feeding_volume
        ) : event.type === "pooping" ? (
          <Stack direction="row" justifyContent="flex-end">
            {[...Array(parseInt(event.misc.poop_size))].map(() => "💩")}
          </Stack>
        ) : null}
      </TableCell>
      <TableCell>
        {isDeleting ? (
          <CircularProgress size={24} />
        ) : (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              setIsDeleting(true);
              onDeleteEvent(event.type, event._id);
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

export default EventRow;
