import axios from "axios";
import CONSTANTS from "../constants";
import EventRow from "./EventRow.react";
import {
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";

const h6Style = {
  align: "left",
  variant: "h6",
  sx: {
    fontWeight: "bolder",
    marginTop: 2,
    marginBottom: 2,
  },
};

function EventsSummary({ events, type, onDeleteEvent }) {
  if (events.length === 0) {
    return (
      <Typography {...h6Style}>{`No ${type} events`.toUpperCase()}</Typography>
    );
  }
  events.sort((eventA, eventB) => eventA.timestamp - eventB.timestamp);
  return (
    <>
      <Typography {...h6Style}>
        {type.toUpperCase()}{" "}
        {type === "feeding" ? "🧑‍🍼" : type === "pooping" ? "💩" : null}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell align="right">
                {type === "feeding" ? "Volume (mL) 🍼" : "Size 🧪"}
              </TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <EventRow
                key={event.timestamp}
                event={event}
                onDeleteEvent={onDeleteEvent}
              />
            ))}
            {type === "feeding" && (
              <TableRow key="total">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bolder" }}
                >
                  TOTAL
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bolder" }}>
                  {events.reduce(
                    (total, event) =>
                      total + parseInt(event.misc.feeding_volume),
                    0
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Summary({ isActive, date }) {
  const [state, setState] = useState({
    feedingEvents: [],
    poopingEvents: [],
    lastFeedingTimestamp: null,
  });

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    setInterval(() => setNow(new Date()), 60);
  });

  useEffect(() => {
    axios.get(`${CONSTANTS.SERVER_URI}/events?date=${date}`).then(({ data }) =>
      setState({
        feedingEvents: data.feeding_events,
        poopingEvents: data.pooping_events,
        lastFeedingTimestamp: data.last_feeding_timestamp,
      })
    );
  }, [isActive, date]);

  const deleteEvent = useCallback(
    (event_type, event_id) => {
      axios.delete(`${CONSTANTS.SERVER_URI}/events/${event_id}`).then(() => {
        if (event_type === "feeding") {
          setState({
            ...state,
            feedingEvents: state.feedingEvents.filter(
              (event) => event._id !== event_id
            ),
          });
        } else if (event_type === "pooping") {
          setState({
            ...state,
            poopingEvents: state.poopingEvents.filter(
              (event) => event._id !== event_id
            ),
          });
        }
      });
    },
    [state]
  );

  const secondsSinceLastFeeding = parseInt(
    now.valueOf() / 1000 - state.lastFeedingTimestamp
  );
  let hoursSinceLastFeeding = Math.floor(secondsSinceLastFeeding / 60 / 60);
  let minutesSinceLastFeeding = Math.round(
    secondsSinceLastFeeding / 60 - hoursSinceLastFeeding * 60
  );
  if (minutesSinceLastFeeding === 60) {
    minutesSinceLastFeeding = 0;
    hoursSinceLastFeeding += 1;
  }

  return (
    <>
      {date === "today" && state.lastFeedingTimestamp == null ? (
        <LinearProgress />
      ) : date === "today" ? (
        <Stack direction="row" spacing={1}>
          <Typography
            sx={{ fontWeight: "bolder" }}
            color={
              secondsSinceLastFeeding < 4 * 60 * 60
                ? "green"
                : secondsSinceLastFeeding < 4.5 * 60 * 60
                ? "blue"
                : "purple"
            }
            variant="body1"
          >
            {hoursSinceLastFeeding === 0 && minutesSinceLastFeeding === 0
              ? "Less than a minute"
              : hoursSinceLastFeeding === 0
              ? `${minutesSinceLastFeeding} minutes`
              : `${hoursSinceLastFeeding} hours ${minutesSinceLastFeeding} minutes`}
          </Typography>
          <Typography variant="body1">since last feeding</Typography>
        </Stack>
      ) : null}
      <EventsSummary
        events={state.feedingEvents}
        type="feeding"
        date={date}
        onDeleteEvent={deleteEvent}
      />
      <EventsSummary
        events={state.poopingEvents}
        type="pooping"
        date={date}
        onDeleteEvent={deleteEvent}
      />
    </>
  );
}

export default Summary;
