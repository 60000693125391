import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import CONSTANTS from "../constants";
import dayjs from "dayjs";
import {
  FormControl,
  MenuItem,
  Rating,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useState } from "react";

const h6Style = {
  align: "left",
  variant: "h6",
  sx: {
    fontWeight: "bolder",
    marginLeft: 4,
    marginTop: 2,
    marginBottom: 2,
  },
};

const defaultState = {
  type: "feeding",
  feeding_volume: 180,
  poop_size: 3,
  is_submitted: false,
  is_submitting: false,
};

function EventEditor({ isActive }) {
  const [state, setState] = useState({
    ...defaultState,
    timestamp: new Date().valueOf() / 1000,
  });

  useEffect(() => {
    setState({ ...defaultState, timestamp: new Date().valueOf() / 1000 });
  }, [isActive]);

  const setEventType = (_, selectedEventType) =>
    setState({ ...state, type: selectedEventType });

  const setEventTimestamp = (value) =>
    setState({ ...state, timestamp: value.unix() });

  const setFeedingVolume = (event) =>
    setState({ ...state, feeding_volume: event.target.value });

  const setPoopSize = (_, selectedPoopSize) =>
    setState({ ...state, poop_size: selectedPoopSize });

  const onSubmit = (e) => {
    e.preventDefault();
    setState({ ...state, is_submitting: true });
    const normalizedState = {
      type: state.type,
      timestamp: state.timestamp,
    };
    if (state.type === "feeding") {
      normalizedState.feeding_volume = state.feeding_volume;
    } else if (state.type === "pooping") {
      normalizedState.poop_size = state.poop_size;
    }

    axios.post(`${CONSTANTS.SERVER_URI}/events`, normalizedState).then(() => {
      setState({ ...state, is_submitted: true, is_submitting: false });
    });
  };

  return (
    <>
      <ToggleButtonGroup
        color="success"
        value={state.type}
        exclusive
        sx={{ marginLeft: 4, marginRight: 4 }}
        onChange={setEventType}
      >
        <ToggleButton value="feeding" sx={{ minWidth: 150 }}>
          Feeding 🧑‍🍼
        </ToggleButton>
        <ToggleButton value="pooping" sx={{ minWidth: 150 }}>
          Pooping 💩
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography {...h6Style}>EVENT TIME 🕰️</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={dayjs(state.timestamp * 1000)}
          sx={{ minWidth: 300 }}
          onChange={setEventTimestamp}
        />
      </LocalizationProvider>
      {state.type === "feeding" ? (
        <>
          <Typography {...h6Style}>VOLUME 🍼</Typography>
          <FormControl color="info" sx={{ minWidth: 300 }}>
            <Select value={state.feeding_volume} onChange={setFeedingVolume}>
              {Array.from(Array(25).keys()).map((index) => (
                <MenuItem key={index} value={index * 10}>
                  {index * 10}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : null}
      {state.type === "pooping" ? (
        <>
          <Typography {...h6Style}>SIZE 🧪</Typography>
          <Rating
            icon={"💩"}
            emptyIcon={"🚽"}
            size="large"
            value={state.poop_size}
            onChange={setPoopSize}
          />
        </>
      ) : null}
      <Stack justifyContent="center" sx={{ marginLeft: 10, marginRight: 10 }}>
        <LoadingButton
          size="large"
          sx={{
            float: "right",
            display: "block",
            marginTop: 8,
          }}
          loading={state.is_submitting}
          loadingIndicator="Submitting..."
          disabled={state.is_submitted}
          variant="contained"
          onClick={onSubmit}
        >
          {state.is_submitted ? "Submitted" : "Submit"}
        </LoadingButton>
      </Stack>
    </>
  );
}

export default EventEditor;
